<template>
  <div>
    <div class="flex-row navbar">
      <img class="logo" src="../static/img/logo.png" @click="goRoleHome" style="cursor: pointer;" />
      <span
        class="navBarTitle"
        @click="goRoleHome"
        style="cursor: pointer;"
      >{{ $t("navbar.title") }}</span>
      <span
        class="navBarSubTitle"
        @click="goRoleHome"
        style="cursor: pointer;"
      >{{ $t("navbar.subTitle") }}</span>
      <div class="flex-wrap"></div>
      <el-dropdown @command="langeSwitch">
        <span class="el-dropdown-link">{{ lanage }}</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="$t('navbar.langueEn')">
            {{
            $t("navbar.langueEn")
            }}
          </el-dropdown-item>
          <el-dropdown-item :command="$t('navbar.langueCn')">
            {{
            $t("navbar.langueCn")
            }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span v-if="isShow" style="margin-right: 20px;">
        <el-link :underline="false" type="primary">{{ $t("currentPosition") }}</el-link>
        <span v-show="lanage === 'English'" style="display: inline-block;width: 5px;"></span>
        <el-link
          :underline="false"
          class="el-link-default"
        >{{ roleId === "2" ? $t("loginPage.investorRegister") : roleId === "3" ? $t("loginPage.enterpriseRegister") : $t("loginPage.supplierRegister") }}</el-link>
        <span style="margin-top: 5px;">&nbsp;>&nbsp;</span>
        <el-link
          :underline="false"
          @click="$router.push(routerOne.path).catch(error => {})"
        >{{ $t(routerOne.title) }}</el-link>
        <span v-show="routerTwo">&nbsp;>&nbsp;</span>
        <el-link v-show="routerTwo" :underline="false">{{ $t("details") }}</el-link>
        <el-button class="btn-logout" @click="goLogout">{{ $t("navbar.tuiLohout") }}</el-button>
      </span>
    </div>
  </div>
</template>

<script>
import Storage from '../utils/storage.js';
export default {
  data() {
    return {
      role: this.$store.getters['auth/user'].role,
      approved: this.$store.getters['auth/user'].approved,
      lanage: '',
      routerList: [{
        icon: 'el-icon-menu',
        path: '/supply-all',
        title: 'adnavPage.overview'
      }, {
        icon: 'el-icon-document',
        path: '/assets-list',
        title: 'adnavPage.AssetsManagement'
      }, {
        icon: 'el-icon-s-claim',
        path: '/receivable-list',
        title: 'adnavPage.receivable'
      }, {
        icon: 'el-icon-bangzhu',
        path: '/financing-list',
        title: 'adnavPage.financingManage'
      }, {
        icon: 'el-icon-s-ticket',
        path: '/repayment-list',
        title: 'adnavPage.repaymentManage'
      }, {
        icon: 'el-icon-s-operation',
        path: '/supply-list',
        title: 'adnavPage.supplyManage'
      }, {
        icon: 'el-icon-wallet',
        path: '/supply-tr',
        title: 'menuI18n.UnitBalance'
      }],
      roleId: '',
      routerOne: {
        icon: 'el-icon-menu',
        path: '/supply-all',
        title: 'adnavPage.overview'
      },
      routerTwo: false,
      isShow: true
    };
  },
  mounted() {
    // 添加访问路由
    this.isShow = !["/root", "/error-code", "/no-power", "/reject", "/review", "/wait", "/invitation-code",
      "/enterprise-create", "/company-create", "/invest-create"].includes(this.$route.path);
    if (this.isShow) {
      this.roleId = sessionStorage.getItem("roleId");
      if (window.performance.navigation.type === 1) {
        console.log("页面被刷新", this.$route.path);
        this.routerOne = this.routerList.find(item => {
          return this.$route.path.toString().includes(item.path);
        });
        this.routerTwo = this.$route.path.toString().length !== this.routerOne.path.length;
      }
    }
    this.lanage = Storage.getLocalItem('i18n') === 'zh-cn' ? '中文' : 'English';
  },
  methods: {
    // navbar先判断角色，其次判断审核状态，状态为2时才可以进入各角色首页，否则一律跳转到登录页面
    goRoleHome() {
      let role = this.$store.getters['auth/user'].role;
      let approved = this.$store.getters['auth/user'].approved;
      let routerLink = "/";
      switch (role) {
        case 1:
          routerLink += approved === 2 ? 'supply-all' : 'login';
          break;
        case 2:
          routerLink += approved === 2 ? 'invest-all' : 'login';
          break;
        case 3:
          routerLink += approved === 2 ? 'enterprise-all' : 'login';
          break;
      }
      this.$router.push({ path: routerLink });
    },
    langeSwitch(command) {
      // this.$message({ message: this.$t('navbar.changeLangePrompt') + command, type: 'success' });
      let local = command === '中文' ? 'zh-cn' : 'zh-en';
      Storage.setLocalItem('i18n', local);
      this.$i18n.locale = local;
      this.lanage = command;
      this.$store.dispatch('auth/language', local);
      window.location.reload();
    },
    // 退出登录
    goLogout() {
      this.$store.dispatch('auth/logoutSuccess');
      this.$router.replace({ path: '/login' });
    },
    menuSwich(command) {
      console.log(command);
      switch (command) {
        case 'person':

          break;
        case 'manage':

          break;
        case 'logout':
          this.$store.dispatch('auth/logoutSuccess', {
            username: '',
            authorization: ''
          });
          history(0);
          this.$router.push({ path: '/login' });

          break;
        default:
          break;
      }
    },
    loginOut() {
      localStorage.removeItem('type');
      this.$store.dispatch('auth/logoutSuccess', undefined);
      this.$router.push({ path: '/login' });
    }
  },
  watch: {
    $route(to, from) {
      if (this.isShow) {
        let isRoute = this.routerList.find(item => {
          return to.path.toString().includes(item.path);
        });
        if (!isRoute) return false;
        this.routerOne = isRoute;
        this.routerTwo = this.$route.path.toString().length !== this.routerOne.path.length;
      }
      console.log(from.path, to.path);
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 60px;
  width: 100vw;
  min-width: 1200px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .logo {
    width: 38px;
    height: 30px;
    margin-left: 40px;
  }
  .navBarTitle {
    margin: 0 20px;
    margin-right: 10px;
    font-size: 30px;
    font-weight: bolder;
  }
  .navBarSubTitle {
    font-size: 24px;
  }
  .el-dropdown {
    margin: 0 10px;
    .el-avatar {
      margin-top: 10px;
      margin-right: 30px;
    }
  }
  .obgTitle {
    display: inline-block;
    font-size: 26px;
    font-weight: 600;
  }
  .btn-out {
    margin-right: 30px;
    border-color: #81d8d0;
  }
}
</style>
