<!--
 * @Author: 卢林
 * @Date: 2019-12-11 17:24:13
 * @LastEditTime: 2020-03-10 16:12:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-test/src/practiceComponents/1.1/ComponentBased.vue
 -->
<template>
  <!-- 侧导航 -->
  <div>
    <adNavbar></adNavbar>
    <el-container>
      <!-- <el-aside class="sideMain"> -->

      <adSideNavgation></adSideNavgation>
      <!-- </el-aside> -->
      <el-main>
        <div class="mainContain">
          <transition name="transitionRouter">
            <router-view />
          </transition>
        </div>
      </el-main>
      <!-- <el-footer class="bottomContain">
        <adBottombar></adBottombar>
      </el-footer>-->
    </el-container>
  </div>
</template>
<script>
// import adNavgation from '../components/ad-navgation';
// import adBottombar from '../components/ad-bottombar';
import adSideNavgation from '../components/ad-side-navgation';
import adNavbar from '../components/ad-navbar';

export default {
  components: {
    // adNavgation,
    // adBottombar,
    adSideNavgation,
    adNavbar
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
<style scoped>
.bottomTitle {
  text-align: center;
}
.bottomContain {
  position: fixed;
  bottom: 0px;
  width: 100%;
  min-width: 1200px;
}
.el-container {
  position: relative;
  height: calc(100vh - 80px);
  min-width: 1200px;
  margin-top: 20px;
}
.el-main {
  padding-top: 0;
}
.mainContain {
  width: 1200px;
  margin: auto;
}
.transitionRouter-enter-active,
.transitionRouter-leave-active {
  /* transition: all 0.4s; */
}

.transitionRouter-enter,
.transitionRouter-leave {
  transform: translate3d(100%, 0, 0);
}
</style>
