<template>
  <div v-if="sideShow">
    <div class="container" :class="widthChange">
      <!-- 菜单开关 -->
      <!-- <el-button circle @click="open" class="fold">
        <i :class="foldBtnICon"></i>
      </el-button>-->

      <el-row type="flex" justify="left" class="sideNavContainer">
        <el-col :span="24">
          <div class="flex-column-center company">
            <span>{{ userInfo.username }}</span>
            <div class="verify">
              <img
                class="verify-img"
                v-if="this.$store.getters['auth/user'].approved === 2"
                src="../static/img/identify.png"
              />
              <span>{{ userInfo.approved === auth.UNKNOWN.value ? $t('responseStatus.unknown') : userInfo.approved === auth.CONFIRM.value ? $t('responseStatus.confirm') : userInfo.approved === auth.PROCESSING.value ? $t('responseStatus.processing') : userInfo.approved === auth.REJECT.value ? $t('responseStatus.reject') : $t('responseStatus.unknown') }}</span>
            </div>
            <img
              class="verify-img"
              style="width: 70px; height: 70px; border-radius: 50%;"
              :src="supplyInfo.companyLogo"
            />
          </div>
          <el-menu
            @select="handleSelect"
            :collapse="isCollapse"
            active-text-color="#81D8D0"
            :router="true"
            :default-active="'/' + this.$route.path.split('/')[1]"
          >
            <el-menu-item v-for="route in pathList" :key="route.path" :index="route.path">
              <i :class="route.icon"></i>
              <span slot="title">{{ $t(route.title) }}</span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      auth: this.$enums.AUTH,
      // 状态
      approved: '',
      supplyInfo: {},
      userInfo: {},
      isCollapse: false,
      widthChange: 240,
      foldBtnICon: 'el-icon-s-fold',
      pathList: [
        {
          icon: 'el-icon-menu',
          path: '/supply-all',
          title: 'adnavPage.overview'
        },
        // {
        //   icon: 'el-icon-s-claim',
        //   path: '/receivable-list',
        //   title: 'adnavPage.receivable'
        // },
        {
          icon: 'el-icon-document',
          path: '/assets-list',
          title: 'adnavPage.AssetsManagement'
        },
        {
          icon: 'el-icon-bangzhu',
          path: '/financing-list',
          title: 'adnavPage.financingManage'
        },
        // {
        //   icon: 'el-icon-s-ticket',
        //   path: '/repayment-list',
        //   title: 'adnavPage.repaymentManage'
        // },
        {
          icon: 'el-icon-s-operation',
          path: '/supply-list',
          title: 'adnavPage.supplyManage'
        }, {
          icon: 'el-icon-wallet',
          path: '/supply-tr',
          title: 'menuI18n.UnitBalance'
        }
        // }, {
        //   icon: 'el-icon-office-building',
        //   // path: '/contract-list',
        //   title: 'adnavPage.contractManage'
        // }]
      ]
    };
  },
  created() {
    // this.isCollapse = this.$store.getters['auth/isSide'];

    // this.$axios.get("/loan/user/detail", {}).then(result => {
    //   this.supplyInfo = result;
    //   sessionStorage.setItem("logo-url", result.logoUrl);
    // }).catch(error => console.log(error));
    if (this.$store.getters['auth/isSide']) {
      this.getUser();
      this.getDetail();
    }
  },
  methods: {
    getUser() {
      this.$axios.get("/v1/supplier/overview").then(result => {
        if (result.code === 0 || result.code === '0') {
          this.userInfo = result.data;
        } else {
          this.$message({ type: 'error', message: result.message });
        }
      }).catch(error => console.log(error));
    },
    getDetail() {
      this.$axios.get("/v1/supplier/detail").then(result => {
        if (result.code === 0 || result.code === '0') {
          this.supplyInfo = result.data;
          // if (this.supplyInfo && this.supplyInfo.companyLogo) {
          //   this.$store.dispatch('auth/companyLogo', this.supplyInfo.companyLogo);
          // }
        } else {
          this.$message({ type: 'error', message: result.message });
        }
      }).catch(error => console.log(error));
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
    // open() {
    //   this.isCollapse = !this.isCollapse;
    //   this.widthChange =
    //     this.widthChange === 'menu-close' ? 'menu-open' : 'menu-close';
    //   this.foldBtnICon =
    //     this.foldBtnICon === 'el-icon-s-fold'
    //       ? 'el-icon-s-unfold'
    //       : 'el-icon-s-fold';
    // }
  },
  computed: {
    sideShow() {
      return this.$store.getters['auth/isSide'];
    }
  }
};
</script>

<style lang="scss" scoped>
.navbg {
  background-color: #f9fafc;
}
.container {
  background-color: #f7f7f7;
  width: 240px;
  // .el-menu-item:hover {
  //   outline: 0 !important;
  //   background: #fff3e0 !important;
  // }
  // .el-menu-item.is-active {
  //   background: #fff !important;
  // }
}
.company {
  height: 320px;
  background: #353e46;
  color: white;
  .verify {
    color: #81d8d0;
    margin: 20px 0;
    .verify-img {
      position: relative;
      top: 2px;
      left: -6px;
    }
  }
}
.menu-close {
  animation: menuClose 500ms 1;
  animation-fill-mode: forwards;
}
.menu-open {
  animation: menuOpen 500ms 1;
  animation-fill-mode: forwards;
}
.fold {
  position: absolute;
  margin-top: 10px;
  background-color: #454545;
  border: 0;
  z-index: 99;
  right: 25px;
  width: 25px;
  height: 25px;
  .el-icon-s-fold,
  .el-icon-s-unfold {
    position: absolute;
    left: 5px;
    top: 5px;
  }
}
@keyframes menuClose {
  from {
    width: 240px;
  }
  to {
    width: 70px;
  }
}
@keyframes menuOpen {
  from {
    width: 70px;
  }
  to {
    width: 240px;
  }
}
</style>
